<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form
          ref="form"
          :model="formInline"
          :inline="true"
          label-position="right"
          class="demo-form-inline"
        >
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="规则名称:">
                <!-- <el-input v-model="formInline.edrsRuleName" placeholder="请输入" maxlength="19"></el-input> -->
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue"
                  :clearable="true"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入所属车场"
                  value-key="ruleName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item label="规则标签:">
                <el-input v-model="formInline.tag" placeholder="请输入" maxlength="200"></el-input>
              </el-form-item>
              <el-form-item label="状态:">
                <el-select v-model="formInline.status" placeholder="全部">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    v-for="item in statusList"
                    :key="item.code"
                    :label="item.desc"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!--第二行-->
              <el-form-item label="时间段:">
                <timeRangePick
                  @timeChange="timeChangeSQ"
                  ref="timeRangePickerFQ"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searParkRecordList();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表-->
      <div class="tableWrapper bgFFF paddingB20">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column
            align="center"
            :label="item.label"
            :prop="item.prop"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
          >
          </el-table-column>
          <el-table-column label="业务数据" align="center" show-overflow-tooltip min-width="150px">
            <template slot-scope="scope">
              <span>{{ scope.row.body }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="$t('searchModule.Creation_time')" align="center" show-overflow-tooltip min-width="150px">
            <template slot-scope="scope">
              <span>{{ scope.row.createdTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="返回结果" align="center" show-overflow-tooltip min-width="150px">
            <template slot-scope="scope">
              <span>{{ scope.row.remark }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" width>
            <template slot-scope="scope">
              <el-button type="text" @click="retry(scope)" v-if="scope.row.statusDesc == '失败'"
                >重试</el-button
              >
              <el-button
                type="text"
                v-if="scope.row.statusDesc != '失败'"
                @click="seeDetails(scope)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
      <refundDetailsDialog
        ref="mychild"
        :dialogVisibleDetails="dialogVisibleDetails"
        @consoleDetails="consoleDetails"
        :refundFlowId="refundFlowId"
      ></refundDetailsDialog>
    </div>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import refundDetailsDialog from "@/views/businessOrderQuery/group/componets/refundDetailsDialog";
import { setIndex } from "@/common/js/public.js";
export default {
  name: "refundOrderQuery",
  data() {
    return {
      dialogVisibleDetails: false,
      defalutDate: [],
      pageNum: 1,
      total: 0,
      pageSize: 15,
      loading: false,
      tableData: [],
      statusList: [],
      refundTypeList: [],
      isRetry: false, // 是否重试
      formInline: {
        edrsRuleName: "",
        tag: "",
        status: "",
        startTime: "",
        endTime: "",
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "edrsRuleName",
          label: this.$t("list.Rule_Name"),
        },
        {
          prop: "tag",
          label: "规则标签",
        },
        {
          prop: "statusDesc",
          label: this.$t("list.state"),
        },
        {
          prop: "retryCount",
          label: "最大重试次数",
        },
      ],
      retryOrderProcessRecordId: "", // 重试中的ID
      refundFlowId: "",
      modelvalue: "",
    };
  },
  watch: {},
  methods: {
    goquery() {
      this.$router.push({
        path: "/record",
      });
    },
    querySearchAsync(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/edrsRule/getNameList";
      this.$axios
        .get(url, {
          data: {
            ruleName: this.modelvalue,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    resetForm() {
      this.formInline = {
        refundFlowId: "",
        edrsRuleName: "",
        status: "",
        flatAccountDealType: "",
        startTime: "",
        endTime: "",
        edrsRuleId: "",
      };
      this.modelvalue = "";
      this.$refs.timeRangePickerFQ.resetTime();
    },
    handleSelect(item) {
      this.formInline.edrsRuleId = item.edrsRuleId;
    },
    consoleDetails() {
      this.dialogVisibleDetails = false;
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
    timeChangeSQ(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    seeDetails(scope) {
      this.$router.push({
        path: "/edrsRecordDetail",
        query: {
          edrsRecordId: scope.row.edrsRecordId,
        },
      });
    },
    // 搜索
    searParkRecordList() {
      this.searParkRecordListFun();
    },
    showLog(name, start, end) {
      if (start && end) {
        let time = new Date(end) - new Date(start);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert(name + "查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    onCopy() {
      this.$message({
        message: "已复制",
        type: "success",
      });
    },
    searParkRecordListFun() {
      let opt = {
        method: "get",
        // url: "/acb/2.0/refundFlow/list",
        url: "/acb/2.0/edrsRecord/list",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          ...this.formInline,
        },
        success: (res) => {
          console.log(res, "kkkk");
          this.loading = false;
          this.retryOrderProcessRecordId = ""; // 清除重试ID
          if (res.state == 0) {
            // this.tableData = setIndex(this.pageNum, res.value.list);
            this.tableData = setIndex(this.pageNum, res.value.list);
            console.log(this.tableData, "kkkk");
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
    /* 退款状态下拉框 */
    getStatusLists() {
      this.$axios
        .get("/acb/2.0/edrsRecord/droplist", {
          data: {},
        })
        .then((res) => {
          if (res.state == 0) {
            this.statusList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 重试
    retry(scope) {
      let opt = {
        method: "post",
        url: "/acb/2.0/edrsRecord/retry",
        data: {
          edrsRecordId: scope.row.edrsRecordId,
        },
        success: (res) => {
          this.loading = false;
          if (res.state == 0) {
            console.log("重试成功");
            this.searParkRecordList();
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        },
      };
      this.$request(opt);
    },
  },
  created() {
    if (this.$route.params.tag) {
      this.formInline.tag = this.$route.params.tag;
    }
    this.defalutDate = [this.dataTimeRest() + " 00:00:00", this.dataTimeRest() + " 23:59:59"];
  },
  mounted() {
    this.searParkRecordList();
    this.getStatusLists();
  },
  components: {
    timeRangePick,
    refundDetailsDialog,
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">


.pagerWrapper {
  text-align: right;
  margin-top: 28px;
  font-size: 12px;
}

.title {
  margin-top: 30px;
}

.warning {
  color: red;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.titleh1 {
  margin: 10px 10px;
  font-size: 15px;
}

.but {
  height: 40px;
  margin-right: 15px;
  margin-bottom: 15px;
  text-align: center;
}

.orderNumberStyle {
  color: #0f6eff;
}

.goquery {
  color: blue;
  cursor: pointer;
}
</style>
